import React from 'react';
import { Grid, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Grid mb={5}>
      <Grid item>
        <Typography align="center">
          We are continuously developing our patented AI for different use cases
          and would love to hear from you. If you have any questions or want to
          know more about our services feel free to contact us:
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center">
          <a href={'mailto:info@factiverse.no'}>Send us an email</a>
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="center">&copy; 2025 by Factiverse AS</Typography>
      </Grid>
      <Grid item>
        <Typography align="center">
          <a href="/GDPR.pdf" download>
            GDPR notice
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
